<template>
  <el-dialog
    :title="form.id ? '修改' : '添加'"
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      label-width="100px"
    >
      <b-row>
<!--        <b-col md="12">-->
<!--          <el-form-item-->
<!--            label="编号"-->
<!--            prop="code"-->
<!--            :rules="[ { required: true, message: '编号不能为空', trigger: 'change' }]"-->
<!--          >-->
<!--            <el-input-->
<!--              v-model="form.code"-->
<!--              placeholder="请输入模板名称"-->
<!--            />-->
<!--          </el-form-item>-->
<!--        </b-col>-->
        <b-col
          md="12"
        >
          <el-form-item
            label="法律"
            prop="lawCode"
            :rules="[ { required: true, message: '法律不能为空', trigger: 'change' }]"
          >
            <el-select
              v-model="form.lawCode"
              placeholder="请选择法律"
              @change="form.lawDetailsId = '';getDetailsOptions()"
            >
              <el-option
                v-for="(law,k) in lawsOption"
                :key="k"
                :label="law.LawName"
                :value="law.lawCode"
              />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col
          md="12"
        >
          <el-form-item
            label="章节"
          >
            <el-select
              v-model="form.lawDetailsId"
              placeholder="请选择章节"
            >
              <el-option
                v-for="(chapter,k) in chapterOption"
                :key="k"
                :label="chapter.labelName"
                :value="chapter.lawDetailsId"
              />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col
          md="12"
        >
          <el-form-item
            label="内容"
            prop="content"
            :rules="[ { required: true, message: '内容不能为空', trigger: 'change' }]"
          >
            <el-input
              v-model="form.content"
              placeholder="请输入内容"
              type="textarea"
              :autosize="autosize"
            />
          </el-form-item>
        </b-col>
      </b-row>
    </el-form>
    <div class="text-center mt20">
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        保 存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import {
  saveOrUpdateLawBestPractice, GetLawBestPracticeByCode, GetLawUnscrambleOptions, GetLawOptions, GetDetailsOptions,
} from '@/api/knowledgeBase/laws'

export default {
  props: {
    cType: {
      type: Number,
    },
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      form: {
        id: 0,
        content: '',
        lawCode: '',
        lawDetailsId: '',
      },
      lawsOption: [],
      chapterOption: [],
      query: this.$route.query,
    }
  },
  methods: {
    dialogOpen() {
      if (this.form.id) {
        this.getDetailById()
      }
      this.getLawOptions()
    },
    dialogClose() {
      this.form = {
        id: 0,
        content: '',
        lawCode: '',
        lawDetailsId: '',
      }
      this.$refs.form.resetFields()
    },
    getLawOptions() {
      GetLawOptions().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.lawsOption = resData.data
        }
      })
    },
    getDetailsOptions() {
      GetDetailsOptions({ lawCode: this.form.lawCode }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.chapterOption = resData.data
          this.form.lawUnscrambleIdList = []
        }
      })
    },
    getDetailById() {
      GetLawBestPracticeByCode({ id: this.form.id }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = { ...resData.data }
          if (this.form.lawUnscrambleIdList === undefined) {
            this.form.lawUnscrambleIdList = []
          }
        }
      })
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          saveOrUpdateLawBestPractice(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.$emit('handleFilter')
            } else {
              error(resData.msg)
            }
          })
        }
      })
    },
  },
}
</script>

<style>
 .icon-btn{
   position: relative;
   top: 10px;
   font-size: 20px;
   cursor: pointer;
 }
</style>
