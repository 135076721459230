<template>
  <el-card>
    <div class="flex-between">
      <div class="flex-start">
        <el-form
          :inline="true"
          :model="listQuery"
        >
          <el-form-item label="法律">
            <el-select
              v-model="listQuery.lawCode"
              clearable
              @change="handleFilter"
              @clearable="handleFilter"
            >
              <el-option
                v-for="(law,k) in lawsOption"
                :key="k"
                :label="law.LawName"
                :value="law.lawCode"
              />
            </el-select>
          </el-form-item>
<!--          <el-form-item-->
<!--            label="章节"-->
<!--          >-->
<!--            <el-select-->
<!--              v-model="listQuery.lawDetailsId"-->
<!--              placeholder="请选择章节"-->
<!--              clearable-->
<!--              @change="handleFilter"-->
<!--              @clearable="handleFilter"-->
<!--            >-->
<!--              <el-option-->
<!--                v-for="(chapter,k) in chapterOption"-->
<!--                :key="k"-->
<!--                :label="chapter.labelName"-->
<!--                :value="chapter.lawDetailsId"-->
<!--              />-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--          <el-form-item>-->
<!--            <el-input-->
<!--              v-model="listQuery.searchStr"-->
<!--              width="180"-->
<!--              placeholder="请输入内容"-->
<!--              class="mr10"-->
<!--              clearable-->
<!--              @clear="handleFilter"-->
<!--            />-->
<!--          </el-form-item>-->

          <el-form-item>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="handleFilter"
            >
              搜索
            </el-button>
          </el-form-item>
        </el-form>

      </div>
      <el-button
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="openDialog(1)"
      >
        新增
      </el-button>
    </div>
    <el-table
      v-loading="loading"
      border
      fit
      highlight-current-row
      :data="tableData"
      stripe
      style="width: 100%"
    >
<!--      <el-table-column-->
<!--        label="编号"-->
<!--        :show-overflow-tooltip="true"-->
<!--        prop="code"-->
<!--      />-->
      <el-table-column
        label="最佳实践"
        :show-overflow-tooltip="true"
        prop="content"
      />
<!--      <el-table-column-->
<!--        label="涉及法律解读点"-->
<!--        :show-overflow-tooltip="true"-->
<!--        prop="lawUnscrambles"-->
<!--      >-->
<!--        <template slot-scope="{row}">-->
<!--          <div v-html="row.lawUnscrambles" />-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column
        label="创建人"
        :show-overflow-tooltip="true"
        prop="createName"
      />
      <el-table-column
        label="创建时间"
        prop="createTime"
      >
        <template slot-scope="{row}">
          {{ row.createTime ? formatDateStr(row.createTime) : '' }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="120px"
      >
        <template slot-scope="{row}">
          <el-link
            class="mr10"
            type="primary"
            @click="openDialog(2, row)"
          >
            <el-tooltip
              content="编辑"
              placement="top"
              effect="light"
            >
              <i class="el-icon-edit" />
            </el-tooltip>
          </el-link>
          <el-link
            :underline="false"
            type="primary"
            @click="delLaw(row.id)"
          >
            <el-tooltip
              content="删除"
              placement="top"
              effect="light"
            >
              <i class="el-icon-delete" />
            </el-tooltip>
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.pageSize"
      @pagination="getList"
    />
    <BestPracticeSave
      ref="bestPracticeSave"
      :c-type="1"
      @handleFilter="handleFilter"
    />
  </el-card>
</template>

<script>
import pagination from '@core/components/pagination/Pagination.vue'
import { error, success } from '@core/utils/utils'
import BestPracticeSave from '@/views/knowledge/bestPractice/BestPracticeSave.vue'
import {
  BestPracticePage,
  DeleteLawBestPracticeByCodes,
  GetLawOptions,
  GetDetailsOptions,
} from '@/api/knowledgeBase/laws.js'

export default {
  components: {
    pagination,
    BestPracticeSave,
  },
  data() {
    return {
      loading: false,
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 10,
        searchStr: '',
        lawCode: '',
        lawUnscrambleId: '',
      },
      tableData: [],
      lawsOption: [],
      chapterOption: [],
    }
  },
  created() {
    this.getLawOptions()
    this.getList()
  },
  methods: {
    openDialog(saveType, row) {
      this.$refs.bestPracticeSave.dialogVisible = true
      if (saveType === 2) {
        this.$refs.bestPracticeSave.form.id = row.id
      }
    },
    getLawOptions() {
      GetLawOptions().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.lawsOption = resData.data
        }
      })
    },
    getDetailsOptions() {
      GetDetailsOptions({ lawCode: this.listQuery.lawCode }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.listQuery.lawDetailsId = ''
          this.chapterOption = resData.data
        }
      })
    },
    delLaw(id) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        DeleteLawBestPracticeByCodes({ ids: [id] }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.handleFilter()
          } else {
            error(resData.msg)
          }
        })
      })
    },
    getList() {
      this.loading = true
      const newListQuery = { ...this.listQuery }
      newListQuery.lawUnscrambleId = newListQuery.lawUnscrambleId ? newListQuery.lawUnscrambleId : 0
      BestPracticePage(newListQuery).then(res => {
        this.loading = false
        this.tableData = res.data.data.list
        this.total = Number(res.data.data.total)
      }).catch(() => {
        this.loading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
  },
}
</script>
